<template>
  <validation-observer ref="validator" slim>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="9">
          <validation-provider
            slim
            :name="$t('hr.imprest.amount')"
            rules="required|min_value:0|max_value:9999999"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.number="imprest.requested.amount"
              v-decimal.unsigned
              :label="$t('hr.imprest.amount')"
              :disabled="loading"
              color="tertiary"
              clearable
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="3">
          <validation-provider slim :name="$t('currency.code')" rules="required" v-slot="{ errors }">
            <currency-picker v-model="imprest.requested.code" :disabled="loading" :error-messages="errors" />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <validation-provider slim :name="$t('hr.imprest.reason')" rules="required|max:512" v-slot="{ errors }">
            <v-text-field
              v-model="imprest.reason"
              :label="$t('hr.imprest.reason')"
              :disabled="loading"
              color="tertiary"
              clearable
              :counter="512"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <validation-provider slim :name="$t('hr.imprest.period')" rules="required" v-slot="{ errors }">
            <date-picker
              v-model="imprest.period"
              :disabled="loading"
              :label="$t('hr.imprest.period')"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <expense-management-picker
            v-model="imprest.expenseManagement"
            :employee="$store.state.auth.user.id"
            :disabled="loading"
          />
        </v-col>
        <v-col cols="12">
          <validation-provider slim :name="$t('global.description')" rules="max:512" v-slot="{ errors }">
            <v-text-field
              v-model="imprest.description"
              :label="$t('global.description')"
              :disabled="loading"
              color="tertiary"
              clearable
              :counter="512"
            />
          </validation-provider>
        </v-col>
      </v-row>
    </v-container>
  </validation-observer>
</template>

<script>
  import Imprest from "@/pages/hr/imprest/imprest/model/Imprest";

  export default {
    name: "ImprestForm",
    props: {
      status: Boolean,
      travel: Object,
      loading: Boolean
    },
    components: {
      CurrencyPicker: () => import("@/components/selectpickers/currency-picker"),
      ExpenseManagementPicker: () => import("@/components/selectpickers/ExpenseManagementPicker")
    },
    data: vm => ({
      imprest: new Imprest()
    }),
    watch: {
      status: {
        immediate: true,
        handler(val) {
          if (val) {
            this.init();
          }
        }
      }
    },
    methods: {
      init() {
        this.imprest = new Imprest();
        this.imprest.travel = this.travel.id;
        this.imprest.employee = this.travel.employee.id;
        if (this.$refs.validator) {
          this.$refs.validator.reset();
        }
      },
      validateAndSave() {
        return new Promise((resolve, reject) => {
          this.$refs.validator.validate().then(valid => {
            if (valid) {
              this.$api.imprestService
                .save(this.imprest)
                .then(response => {                  
                  if (!response.data.error) {
                    resolve(true);
                  } else {
                    reject(response.data.error);
                  }
                })
                .catch(reject);
            } else {
              resolve(false);
            }
          });
        });
      }
    }
  };
</script>
